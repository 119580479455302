<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="provider-product" :query="{}">
      <DefaultBtn
        titleButton="Salvar"
        icon="edit"
        @click="addOrUpdateProvider"
      />
    </Mural>
    <form>
      <div class="content-new-training flex row col-12 q-pa-md">
        <div class="col-6">
          <div class="form-input col-12 column">
            <div class="row">
              <span class="title-input-form">Nome do Fornecedor</span>
            </div>
            <div class="row">
              <q-input
                class="col-10"
                bg-color="grey-3"
                v-model="provider.name"
                color="default-pink"
                dense
                type="text"
                outlined
                :rules="[(val) => !!val || 'O campo nome é obrigatório.']"
              />
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-input col-12 column">
            <div class="row">
              <span class="title-input-form">CNPJ do Fornecedor</span>
            </div>
            <div class="row">
              <q-input
                class="col-10"
                bg-color="grey-3"
                v-model="provider.cnpj"
                color="default-pink"
                dense
                type="text"
                mask="##.###.###/####-##"
                unmasked-value
                outlined
                :rules="[(val) => !!val || 'O campo cnpj é obrigatório.']"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </div>
</template>

<script>
import WareHouseService from "@/services/WareHouseService";
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import InputNumber from "@/components/shared/inputs/InputNumber.vue";
import { ref, reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ENV } from "@/utils/env";
import { date, useQuasar } from "quasar";

export default {
  name: "NewProviderProduct",
  components: {
    Mural,
    DefaultBtn,
    InputNumber,
  },
  setup() {
    let _wareHouseService = new WareHouseService();
    let breadcrumbs = ref(["Adicionar Fornecedor"]);
    let loading = ref(false);

    let route = new useRoute();
    let router = new useRouter();
    let $q = new useQuasar();

    let provider = reactive({
      name: null,
      cnpj: null,
      address: null,
      number: 0,
      complement: null,
      city: null,
      state: null,
      country: null,
      postal_code: null,
    });

    onMounted(async () => {
      _getProviderProduct();
    });

    async function _getProviderProduct() {
      const providerId = route.query.providerId;
      if (providerId != null) {
        _showLoading();
        breadcrumbs.value[0] = "Editar Fornecedor";
        let response = await _wareHouseService.getProvider(providerId);
        provider.name = response.data.name;
        provider.cnpj = response.data.cnpj;
        _hideLoading();
      }
    }

    async function addOrUpdateProvider() {
      const providerId = route.query.providerId;

      _showLoading();
      if (providerId == null) {
        await _wareHouseService
          .addProvider(provider)
          .then((response) => {
            loading.value = false;
            router.push({ name: "provider-product" });
          })
          .catch((error) => {
            $q.notify({
              message: error.response.data.toString(),
              color: "red-9",
              position: "top",
            });
            loading.value = false;
          });
      } else {
        await _wareHouseService
          .updateProvider(providerId, provider)
          .then((response) => {
            loading.value = false;
            router.push({ name: "provider-product" });
          })
          .catch((error) => {
            $q.notify({
              message: error.response.data.toString(),
              color: "red-9",
              position: "top",
            });
            loading.value = false;
          });
      }
    }

    function _showLoading() {
      loading.value = true;
    }

    function _hideLoading() {
      loading.value = false;
    }

    return {
      breadcrumbs,
      provider,
      loading,
      addOrUpdateProvider,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .page-content {
    height: 85vh;
    overflow-y: auto;
  }
  .title-form {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    opacity: 0.45;
  }

  .content-new-training {
    height: 68vh;

    .content-thumb-training {
      height: 100%;
      .thumb-training {
        border-radius: 10px;
        .q-img {
          border-radius: 10px;
        }
      }
      .btn-thumb-training {
        .q-btn {
          text-transform: none !important;
          border-radius: 8px;
        }
      }
      .label-title-menus {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #686868;
      }
    }
    .content-form-training {
      height: 100%;

      .form-input {
        .title-input-form {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          opacity: 0.45;
        }
      }
    }
  }

  @media (min-width: 800px) {
    flex-direction: row;
  }
}
</style>
